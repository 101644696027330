'use strict';

(function () {
  class MoveRepairModalCtrl {
    constructor($scope, $uibModalInstance, $state, Turbine, Site, Repair, Blade, options, usSpinnerService, toastr, Util, Auth, ActivityLog) {
      this.Util = Util;
      this.Blade = Blade;
      this.Site = Site;
      this.Repair = Repair;
      this.Turbine = Turbine;
      this.toastr = toastr;
      this.usSpinnerService = usSpinnerService;
      this.$uibModalInstance = $uibModalInstance;
      this.isDisabled = false;
      this.errors = {};
      this.options = options;
      this.submitted = false;
      this.ActivityLog = ActivityLog;
      Auth.isLoggedInAsync(loggedIn => {
        if (loggedIn) {
          this.currentUser = Auth.getCurrentUser();
          this.init();
        } else {
          $state.go('login');
        }
      });
      console.log(this.options);
      if (this.options.repair && this.options.repair._id) {
        this.repair = this.options.repair;
      }

      $scope.$on('$stateChangeStart', function () {
        $uibModalInstance.dismiss();
      });
    }

    init() {
      this.Site.getSitesWithoutPopulate().$promise
        .then(response => {
          if (response.data && response.data.length) {
            this.sites = response.data;
            this.sites = _.orderBy(this.sites, 'name')
          }
          this.loading = false;
          this.stopSpin('spinner-1');
          this.isSiteLoaded = true;
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
          this.stopSpin('spinner-1');
          this.isSiteLoaded = true;
        })
    }

    getSiteTurbines() {
      console.log('this.site', this.site);
      this.startSpin('spinner-1');
      if(this.site && this.site._id) {
        this.Site.getTurbinesBySite({ id: this.site._id }).$promise
        .then(turbines => {
          if (turbines.data) {
            console.log('turbines.data', turbines.data);
            turbines.data.turbines.forEach((turbine) => {
              let displayTurbine = "";
              if (turbine.name) {
                displayTurbine += turbine.name;
              }
              if (turbine.model) {
                displayTurbine += " " + turbine.model;
              }
              if (turbine.serial) {
                displayTurbine += " (" + turbine.serial + ")";
              }
              turbine.displayTurbine = displayTurbine.trim();
            });

            turbines.data.turbines = _.sortBy(
              turbines.data.turbines,
              (turbine) => {
                return [turbine.displayTurbine.toLowerCase()];
              }
            );
            this.turbines = turbines.data.turbines;
            this.stopSpin('spinner-1');
          }
        })
        .catch(err => {
          console.log(err);
          this.turbines = [];
          this.stopSpin('spinner-1');
        })
      } else {
        this.turbines = [];
        this.stopSpin('spinner-1');
      }
    }

    getTurbineBlades() {
      console.log('this.turbine', this.turbine);
      this.startSpin('spinner-1');
      if(this.turbine && this.turbine._id) {
        this.Turbine.getBladesByTurbine({ id: this.turbine._id }).$promise
        .then(blades => {
          if (blades.data) {
            console.log('blades.data', blades.data.blades);
            blades.data.blades.forEach((blade) => {
              let displayBlade = "";
              if (blade.position) {
                displayBlade += blade.position;
              }
              if (blade.model) {
                displayBlade += " (" + blade.model + ")";
              }
              blade.displayBlade = displayBlade.trim();
            });

            blades.data.blades = _.sortBy(blades.data.blades, (blade) => {
              return [blade.displayBlade.toLowerCase()];
            });
            this.blades = blades.data.blades;
            this.stopSpin('spinner-1');
          }
        })
        .catch(err => {
          console.log(err);
          this.blades = [];
          this.stopSpin('spinner-1');
        })
      } else {
        this.blades = [];
        this.stopSpin('spinner-1');
      }
    }

    // updateBladeList(site) {

    // }

    moveRepair(form) {
      this.submitted = true;
      this.serverError = null;
      if (form.$valid) {
        this.isDisabled = true;
        this.startSpin('spinner-1');
        if (this.repair && this.repair._id) {
          this.Repair.moveRepair({
            _id: this.repair._id,
            oldBladeId: this.repair.blade._id,
            newBladeId: this.newBlade._id
          }).$promise
            .then(repair => {
              this.stopSpin('spinner-1');
              console.log('move repair result:', repair);
              this.bladeStr = '';
              if(this.repair.blade.make) {
                this.bladeStr+=this.repair.blade.make+' ';
              }
              if(this.repair.blade.model) {
                this.bladeStr+=this.repair.blade.model;
              }
              if(this.repair.blade.name) {
                this.bladeStr+=this.repair.blade.name;
              }

              this.newBladeStr = '';
              if(this.newBlade.make) {
                this.newBladeStr+=this.newBlade.make+' ';
              }
              if(this.newBlade.model) {
                this.newBladeStr+=this.newBlade.model;
              }
              if(this.newBlade.name) {
                this.newBladeStr+=this.newBlade.name;
              }
              
              const activityLogData = {
                site: this.site,
                turbine: this.turbine,
                blade: this.newBlade._id,
                // finding: this.repair && this.repair.finding && this.repair.finding._id ? this.repair.finding._id : '',
                repair: this.repair._id,
                user: this.currentUser._id,
                source: 'web',
                action: 'move',
                type: 'repair',
                sourceScreen: 'turbine',
                eventString: `Repair work order <a href="/repair/${this.repair._id}">${this.repair.woId}</a> was moved from blade <a href="/blade/${this.repair.blade._id}">${this.bladeStr}</a> to blade <a href="/blade/${this.newBlade._id}">${this.newBladeStr}</a> by ${this.currentUser.name}`
              }
        
              this.ActivityLog.save(activityLogData).$promise
                .then(activityLog => {
                  console.log(activityLog);
                })
                .catch(err => {
                  console.log('Error inserting activityLog', err);
              });
              this.$uibModalInstance.close(repair);
            })
            .catch(err => {
              this.isDisabled = false;
              this.errors = {};
              this.stopSpin('spinner-1');
              this.handleError(err, form);
            });
        }
      }
    }

    handleError(err, form) {
      if (err && err.data && err.data.meta && err.data.meta.error_message) {
        if (err.data.meta.code && err.data.meta.code != 422 && !this.Util.isArray(err.data.meta.error_message)) {
          this.serverError = err.data.meta.error_message || 'Internal Server Error';
        } else {
          err.data.meta.error_message.forEach(errorMessage => {
            for (let fieldName in errorMessage) {
              if (form[fieldName]) {
                form[fieldName].$setValidity('mongoose', false);
                this.errors[fieldName] = errorMessage[fieldName];
              }
            }
          })
        }
      } else {
        this.serverError = 'Internal Server Error';
        console.log(err);
      }
    }

    cancelMove() {
      this.$uibModalInstance.dismiss('cancel');
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }
  }

  angular.module('windmanagerApp')
    .controller('MoveRepairModalCtrl', MoveRepairModalCtrl);
})();
